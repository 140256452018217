/* fonts.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Playfair+Display&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');
html,
body {
	width: 100%;
	justify-content: center;
  	align-content: center;
	padding: 0;
	margin: 0;
	font-family: 'Nunito', sans-serif;
	/* background: rgba(75, 159, 225, 0.25); */
	/* background: url(./assets/image2vector.svg);
	background-position: 50% 0; */
	/* background-size: fill; */
	/* background-repeat: no-repeat; */
	background: white;
    background: linear-gradient(137deg, rgba(255,255,255,1) 43%, rgba(160,135,255,1) 100%);
	color: #000000;
	user-select: none;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
  	box-sizing: border-box;
}

#container {
	grid-column: 1;
	box-sizing: border-box;
	padding: 3em 4em;
	background: white;
	width: 50%;
	top: 0;
	left: 0;
	z-index:5;
}

#cardcontainer {
	grid-column: 1;
	box-sizing: border-box;
	padding: 3em 4em;
	background: transparent;
	width: 50%;
	top: 0%;
	left: 50%;
	z-index:5;
	background-position: center;
	background-size: cover;
	z-index:5;
}
@media (max-width: 770px) {
	body{
		grid-template-columns: repeat(1, 1fr);
	}
	#container {
		height: 50vh;
		width: 100%;
		margin: 0;
		padding: 15em 0em;
		/* position: relative; */
	}
	#cardcontainer {
		top: 50%;
		width: 100%;
		left: 0;
		height: 50vh;
		padding: 3em 0em;
	}
}